import { template as template_9286d43899d641a89d60289549221d07 } from "@ember/template-compiler";
const FKText = template_9286d43899d641a89d60289549221d07(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
