import { template as template_9a145d644b9e4c4bb72a055b4ad86759 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_9a145d644b9e4c4bb72a055b4ad86759(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
