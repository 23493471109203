import { template as template_8f75e40f9ff14b41895077d97000f5f4 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_8f75e40f9ff14b41895077d97000f5f4(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
