import { template as template_5009b45ef4da4a9f8d0067774ed859a6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_5009b45ef4da4a9f8d0067774ed859a6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
